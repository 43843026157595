/* .css-yk43mc-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 1px!important;
}

.MuiOutlinedInput-root {
    padding: 2px!important;
} */

.css-d8n6kg-MuiAutocomplete-root .MuiOutlinedInput-root {
    
    padding-top: 0px!important;
    padding-right: 0px!important;
    
    padding-left: 0px!important;
}
    
.MuiDialog-paperWidthLg {
    max-width: 600px!important;
    border: 1px!important;
    border-radius: 18px!important;
}
    

.css-ypiqx9-MuiDialogContent-root {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 20px 20px!important;
    
}
/* .MuiOutlinedInput-input {
    padding: 16.5px 26px!important;
    /* border-radius: 62px; */
 */


.MuiPaper-rounded {
    border-radius: 18px!important;
}
.MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    position: relative;
    /* height: 50px; */
    font-size: 16px;
    
    
    border: 1px!important;
    border-radius: 5px!important;
    box-sizing: border-box;
    align-items: center;
    font-family:'Nunito Sans', sans-serif !important;
    font-weight: 400;
    line-height: 1.1876em;
}
.MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;

    align-items: center;
    justify-content: flex-end;
    padding-bottom: 40px!important;
    padding-right: 37px!important;
}